body{
  background-color: rgb(28,30,43);
  font-family: 'Poppins', sans-serif;
}
h1,h2,h3,h4,h5,h6,p,span,a{
  font-family: 'Poppins', sans-serif;
}

/* =============================Dashboard Page================================================================================================================================================================================================================== */
.dashboard-left-side-div-wrapper-Main{
  padding:30px;
}
.dashboard-left-side-div-one-wrapper {
  background-color: #282F42;
  padding: 16px;
  border-radius: 5px;
}
.dashboard-left-side-div-one-wrapper h3 {
  color: white;
  font-size: 17px;
  margin-bottom: 0px;
  text-align: start;
}
.dashboard-left-side-div-one-inner-div {
  text-align: end;
  margin-top: -8px;
  /* display: flex;
  align-items: flex-start; */
  justify-content: space-between;
  padding-left: 11px;
  padding-right: 0px;
}
.dashboard_page_battery_icon_wrapper{
  display: flex;
  align-items: flex-start;
}
.dashboard-left-side-div-one-wrapper span {
  color: rgb(113, 207, 98);
  font-size: 13px;
}
.dashboard-left-side-div-one-wrapper-battery-div p {
  margin-bottom: 0px;
  color: white;
  font-size: 8px;
  margin: 10px;
  margin-top: 0px;
  margin-bottom:0px;
}
.dashboard-left-side-div-one-wrapper-battery-div {
  display: inline-block;
  text-align: center;
}
.dashboard-left-side-div-two-wrapper-h5 {
  color: white;
  font-weight: 300;
  font-size: 15px;
}
.dashboard-left-side-div-three-wrapper {
  margin-top: 16px;
}
.dashboard-left-side-div-four-wrapper {
  margin-top: 16px;
}
.dashboard_page_Order_Id_Wrapper{
  display: flex;
  justify-content: space-between;
  /* margin-bottom:5px; */
  margin-right:22px;
  width:100%;
}
.dashboard_page_Order_Id_Wrapper_inner_div{
  display: flex;
}
.dashboard_page_Order_Id_Wrapper_inner_div h3{
color:white;
font-size:18px;
margin-bottom:0px;
align-self: center;
}
.dashboard-left-side-div-two-Map-Container-one
{
  background-color: #282F42;
  height: 170px;
  overflow: scroll;
}
.dashboard-left-side-div-two-Map-Container-two
{
  background-color: #282F42;
  /* height: 170px; */
   /* border:3px solid rgb(251,98,98) !important; */
   border-radius: 5px !important;
}
.dashboard-left-side-div-two-Map-Container-two h4 {
  color: white;
  font-size: 17px;
  text-align: center;
  padding: 19px 13px;
}
.connectionstatus-red{
  background-color:rgb(220,53,69);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 7px;
  margin-left: 3px;

}
.connectionstatus-green{
  background-color:  rgb(113, 207, 98);
  width: 13px;
  height: 13px;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 7px;
  margin-left: 3px;

}
.dashboard_left_side_available_div {
  text-align: left;
}
.dashboard_left_side_available_div h4 {
  font-size: 10px;
  color: white !important;
}

.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar{
background-color:#282F42;
color:#fff !important;
width:10px;
}
.col-md-9.dashboard-right-side-div-wrapper-Main h2 {
  font-size: 22px;
  color: white;
  padding: 50px;
  padding-bottom: 0px;
  font-size: 17px;
  padding-left: 30px;
}
.leaflet-marker-icon{
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}
@keyframes example {
  0%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45); }
  50%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45); }
  100% {border:none;transition: 1s ease-in-out}
}
.Dashboard_page_Map_wrapper_Main_whole img {
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}
.Dashboard_page_Map_wrapper_Main_whole {
  padding: 2rem;
  padding-top: 1rem;
  padding-right:0px;
  padding-left:0px;
}
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-track{
  color:#ffffff !important;
} 
.dashboard-left-side-div-two-Map-Container-one::-webkit-scrollbar-thumb {
  background-color:#7d7d7d !important;

}
.dashboard-left-side-div-two-robot-button-Container.d-flex-center{
  background-color: #282F42;
  border-radius: 5px;
}
.dashboard-left-side-div-two-robot-button-Container button {
  border-radius: 5px;
  padding: 5px 24px;
  background-color: rgb(189,196,224);
  border: none !important;
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  margin: 17px;
  margin-left: 10px;
  margin-right: 10px;
  border:2px solid transparent;
}
.dashboard-right-side-div-wrapper-Main{
  background-color: #282F42;
  height:auto;
}
.Dashboard-Page-bottom-div-Main-wrapper{
  width:100%;
  background-color: black;
  position: relative;
  top:0;
  padding: 10px  25px;
  /* z-index:999999 !important; */
}
.Dashboard_page_top_div_inner_div{
  margin-left:20px;
  margin-right:20px;
}
.Dashboard_page_bottom_div_inner_one_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:25%;
}
.Dashboard_page_bottom_div_inner_two_div{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:85%;
}
.Dashboard_page_bottom_div_inner_two_div button{
  margin-right:10px;
  margin-left:10px;
}
.Dashboard_page_bottom_div_inner_two_div_inner_one{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Dashboard_page_bottom_div_inner_two_div_inner_two{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.DashBoard-page-Main-Whole-wrapper{
  padding-left: 0px !important;
  padding-right: 0px !important;
  overflow: hidden;
}
.Dashboard-Page-bottom-div-Main-wrapper-content-p {
  color: white !important;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 0px;
  margin-left:-6px;
}
.direction_toggle_p{
  color: white !important;
  text-transform: capitalize;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 0px !important;
  margin-left:-6px;
}
.Dashboard-Page-bottom-div-Main-wrapper-content-span{
color: rgb(189,196,224);
}
.Dashboard-Page-bottom-div-Main-wrapper.d-flex-center button {
  background-color: #282F42;
  border:2px solid #a2a2a2;
  border-radius: 5px;
  padding: 4px 22px;
  font-size: 12px;
  color:white;
  font-weight: 200;
}
.Dashboard-page-teleop-button-disabled{
  background-color: #78797a !important; 
}
.Dashboard-Page-Power-button{
  background-color: #282F42;
  border-radius: 30px;
   width:35px !important;
   height:35px !important;
   padding:5px;
}
.col-md-9.dashboard-right-side-div-wrapper-Main {
  height: 90vh;
}
.Stop-button-dashhboard-page{
  background-color: rgb(228 51 41) !important;
  color:white !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  border:none !important; 
  padding: 6px 33px !important;
}
button#long-button {
  border: none;
  background-color: transparent;
  padding: 4px 0px;
}

.Dashboard_page_sidebar_button{
  background-color: transparent;
  color:white;
  border:none;
  font-family: "Poppins",sans-serif;
}
.play-icon{
  cursor:pointer;
}
#long-menu ul{
  padding:0px !important;
}
.Dashboard-page-teleop-button{
  /* border:2px solid rgb(220,53,69) !important;  */
}
.Dashboard_page_sidebar_menuItem {
  padding: 10px !important;
  border-bottom: 1px solid #eaeaea!important;
  /* justify-content: end !important; */
}
.Dashboard_page_sidebar_menuItem_last{
  padding: 10px !important;

}
.Error-state-teleop-ack-button{
  background-color: rgb(220,53,69) !important;
  color:white !important; 
  font-size: 10px;
  position: absolute;
  right: 0rem;
  top: -10px;
  border:none !important;
}
.dashboard_page_Map_heading_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:12px;
  margin-top:12px;
}
.dashboard-left-side-div-two-robot-button-Container button.Dashboardpage_CloseDoor_button{
  border:2px solid rgb(189,196,224) !important;
  color:rgb(189,196,224);
  background:none !important;
}
.Teleop_completed_btn{
  border:2px solid rgb(189,196,224) !important;
  color:#282F42 !important;
  background:rgb(189,196,224) !important;
  font-weight: bold !important;
}
.Dashboard_page_OpenDoor_deactivate{

}
.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button {
  border:2px solid #e43329;
  background: #bdc4e0;
  border-radius: 7px;
  display: block;
    margin: 0 auto 10px;
    padding: 7px 20px;
    max-width: 150px;
    font-weight: bold;
    text-align: center;
}
.dashboard-left-side-div-one-wrapper .Dashboard-page-teleop-button.Teleop_completed_btn {
  border:2px solid #e43329 !important;
  background: #FFF !important;
  border-radius: 7px;
  display: block;
    margin: 0 auto 10px;
    padding: 7px 20px;
    max-width: 200px;
    font-weight: bold;
    font-size: 16px !important;
    text-align: center;
}
.direction-toggle{
  display: flex;
  align-items: center;

}
/* =============================End================================================================================================================================================================================================================== */

/* ============================LoginPage========================================================================================================================================================================================================================== */

.Login-page-form {
  width: 35%;
  margin: auto;
  background: rgb(40,47,66);
  /* box-shadow:0px 4px 33px 12px rgb(40,47,66); */
  padding: 25px;
  padding-top: 37px;
  border-radius: 8px;
  text-align: center;
}
input:focus{
  background-color: transparent !important;
}
/* input:-webkit-autofill{
  background-color: transparent !important;
  color:transparent !important;
} */
.Login-page-form input:-webkit-autofill,
.Login-page-form input:-webkit-autofill:hover, 
.Login-page-form input:-webkit-autofill:focus,
.Login-page-form input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px rgb(40,47,66) inset !important;
    -webkit-text-fill-color:rgb(189,196,224) !important;
  }
.LoginPage-Main-wrapper {
  height:100vh;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  /* background-color: white; */
  display: flex;
  width:100%;
}
.Login-page-form .css-1a1fmpi-MuiInputBase-root-MuiInput-root{
  width:95%;
  margin:auto;
}
.Login-page-form-logo-image {
  width: 25%;
}
img.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive:last-of-type{
margin-left:-22px !important;
margin-top:-30px !important;
}
.Loginpage-main-form-inner-div{
margin-top:44px !important;
}
.Login-form-p {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: rgb(1,115,212);
  font-weight: 600;
  margin-top:10px  !important;
  margin-bottom:0px !important; 
}
.Login-page-form h6 {
  margin: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  font-size: 17px;
  margin-top: 0px !important;
}
.Loginpage\=-main-form {
  margin: 24px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 46px;
  margin-top: 46px;
}
.Login-page-form input{
  font-size: 19px !important;
  font-weight: 600 !important;
  color:rgb(189,196,224) !important;
  letter-spacing: 0px !important;
}
.Login-page-form .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 15.9px;
  font-family: 'Poppins', sans-serif;
  color:rgb(189,196,224);
  font-weight: 500;
}

.Loginpage-btn-wrapper-btn{
  color: black !important;
  text-transform: capitalize !important;
  font-family: 'Poppins', sans-serif !important;
  font-size:20px !important;
  background-color: rgb(189,196,224) !important;
  padding: 10px !important;
  border-radius: 7px !important;
  width: 100% !important;
  border:none;
  font-weight: 700;
}
.Login-page-form .css-1d1r5q-MuiFormHelperText-root.Mui-error{
font-weight: 400;
font-size: 10px;
padding-left:39px;
}
.Login-page-form .css-dmmspl-MuiFormGroup-root{
  padding-left: 15px
}
img.Logout-image {
  width: 100%;
  margin-left: 2px;
}
.Login-page-remember-checkbox{
width:25px;
height:25px;
}
.Login-page-remember-checkbox-span{
  margin:10px;
  color:rgb(189,196,224);
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input:-webkit-autofill{
  background-color: transparent !important;
}
/* =============================End================================================================================================================================================================================================================== */

/* ==================SelectrFleetPage=======================================================================================================================================================   */
.SelectFleetPage-content-wrapper-Main{
  display: flex;
  flex-wrap: wrap;
}
.SelectFleetPage-inner-li-div{
  width:400px;
  margin:7px;
  cursor: pointer;
  border-top: none !important;
  cursor: pointer;
  position: relative !important;
}
.SelectFleetPage-content-wrapper-Main.border-red {
  justify-content: flex-start;
  width: 407px;
  margin:5px;
  background-color:rgb(40,47,66);
}
.Select-page-fleetName {
  color: #b7b7b7 !important;
  font-size: 16px;
}
.SelectFleetPage-content-wrapper-Main .Dashboard-content-div-one-left-side-h3{
  text-align: start !important;
  /* border-bottom: 3px solid #efefef; */
  padding: 6px;
  font-size: 13px;
  color:white !important;
}
.SelectFleetPage-content-wrapper-Main .d-flexx-center {
  padding-right: 30px;
  padding-left: 30px;
}
.container.SelectFleetPage-wrapper-Main {
  margin-top: 3rem;
}
.container.SelectFleetPage-wrapper-Main .SelectPageMain-Heading {
  margin: 22px;
  margin-left: 0px;
}
.SelectFleet-Page-p-tag {
  font-size: 21px;
}
.SelectFleet-Page-h5-tag {
  font-weight: 800;
}
.SelectFleetPage-inner-li-div-robot-id{
margin-bottom:0px;
}
.SelectPage-robotList-wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: rgb(40,47,66);
  padding:11px;
  width: 100%;
  justify-content: flex-start;
}
.Select-Page-Robot-Message-Heading{
  color:#ffffff !important;
  text-transform: uppercase !important;
  font-size:15px;
}
.SelectFleet-page-Error-box-wrapper{
display: flex;
justify-content: flex-start;
align-items: center;
} 
/* ==================End=======================================================================================================================================================   */
/* =====================Header===================================================================================================================================================================== */
.Header-Add-new-order-btn{
  border-radius: 30px;
}
.Header-Main-wrapper {
  /* box-shadow: 0px 16px 14px 5px lightgrey; */
  box-shadow: 0 0 80px rgba(0,0,0,0.1);
  padding: 14px 65px;
}
.HeaderMain-logo-image{
width:100%;
}

.HeaderMain-logo-image {
  width: 7%;
}
button.Header-Add-new-order-btn {
  font-size: 14px !important;
  font-weight: 600 !important;
  padding: 6px 20px !important;
  background-color: rgb(1,115,212) !important;
  color: #ffffff !important;
  border-radius: 20px !important;
}
.container-fluid.SelectFleetPage-wrapper-Main {
  padding-left: 55px;
  padding-right: 55px;
  margin-bottom: 6rem;
}
.row.justify-content-between{
justify-content: end;
}
/* =========================End================================================================================================================================================================================= */
/* =============================Disengage Modal======================================================================================================================================================================================== */
.Modal-wrapper-p{
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: 39px;
letter-spacing: 1.6000000238px;
color: #fff;
text-align: center;
margin-bottom: 20px;
}

/* ==================================End====================================================================================================================================================================================================================== */
.No-robots-available{
font-size:13px;
color:white;
}
.RobotActions-content-wrapper-div {
  color: #ffffff !important;
  padding: 19px;
  border-radius: 5px;
}
.RobotActions-content-wrapper-div h3{
  font-size:15px;
  margin-bottom:10px;
}
.RobotActions-content-wrapper-div p{
  font-size:13px;
  margin-bottom:0px;
}
.RobotActions-button-wrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.RobotActions-button-wrapper button{
  border:none;
  color:white !important;
  border-radius:5px;
  font-size:13px;
  padding:5px 15px;
  margin:5px;
}
.RobotActions-Start-btn{
background-color: rgb(40,167,69);
}
.RobotActions-Start-btn-disabled{
  background-color: rgb(117, 189, 134);
  color: rgb(223, 219, 219) !important;
}
.RobotActions-cancel-btn{
background-color: rgb(220,53,69);
}
/* =============================Responsive================================================================================================================================================================================================================== */
/* =============================End================================================================================================================================================================================================================== */
#map { 
  height: 100vh;
}

.leaflet-popup-content-wrapper {
  width: 100%;
}
@keyframes example {
  0%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45);   border-radius: 100%;}
  50%   {transition: 1s ease-in-out;box-shadow: 0px 0px 0px 5px rgb(253, 243, 45);   border-radius: 100%;}
  100% {border:none;transition: 1s ease-in-out;  border-radius: 100%;}
}
.gm-style div:nth-child(2) div:nth-child(2) div div:nth-child(3) div{
  position: relative;
  animation-name: example;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  border-radius: 100%;
}
.gm-style div:nth-child(2) div:nth-child(1) div:nth-child(2) div:nth-child(1) {
    position: absolute;
    left: 0px;
    top: -7px !important;
    z-index: 30;
}
.battery-icon-css {
  width: 35px; position: relative;
}
.battery-icon-css:after {
  content: ' ';
  border-radius: 3px;
  width: 3px;
  height: 5px;
  background: #fff;
  position: absolute;
  top: 8px;
  right: 0px;
}
.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 20px;
  padding-left: 14px;
  color: white;
  text-align: center;
  padding:0px;
}
.RobotActions_popup_content_wrapper{
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(189,196,224);
  margin-top: 26px;
  margin-bottom: 35px;
  padding-top:10px;
  padding-bottom:10px;
}
.RobotActions_popup_content_wrapper p{
  font-size:16px !important;
  color: #282F42;
}
.RobotActions_popup_content_wrapper h4{
  color: #282F42;
  color: #282F42;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
.Cancel-order-Modal-Mainwrapper{
  padding:32px;
  padding-right:0px ; 
  padding-left:0px
}
.Cancel-order-Modal-Mainwrapper h5{
  color:rgb(40,167,69);
  text-align:center;
  font-weight:600;
  font-size: 24px;
}
.Cancel-order-Modal-Mainwrapper p {
  margin-bottom: 0px;
  font-size: 24px;
  font-weight:600;
}
.Modal-button-Main-btn{
  border-radius: 7px !important;
  border:1px solid white !important;
  color:white !important;
  padding:8px 55px !important;
  font-weight: 600 !important;
  margin: 10px !important;
  margin-top: 0px !important;
  }
.yes-red-active{
    background: white !important;
    color:black !important;
}
.Modal-button-Main-wrapper {
  margin-top: 22px;
  text-align: center;
}
.dashboard-left-side-div-one-wrapper_ERROR {
  border: 5px solid #fb6262;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}
.dashboard-left-side-div-one-wrapper.state_Teleop_Moving{
  border:5px solid #7859bc;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}
.robotRunningState_teleopmoving{
  background-color: #7859bc;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #7859bc;
}
.teleopMoving_bordertop{
 border-top:8px solid #7859bc;
}
.dashboard-left-side-div-one-wrapper.state_Auto_run_moving{
  border:5px solid #40B4D0;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}
.robotRunningState_autorunmoving{
  background-color: #40B4D0;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #40B4D0;
}
.AutoRun_moving{
border-top:8px solid #40B4D0
}
.dashboard-left-side-div-one-wrapper.state_Auto_Obstacle-Teleop_Obstacle{
  border: 5px solid #e6883c;
  padding: 25px 10px;
  border-radius: 7px;
  background-color: black;
} 
.robotRunningState_state_Auto_Obstacle-Teleop_Obstacle{
  background-color: #e6883c;
  border-radius: 10px;
  padding: 10px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #e6883c;
}
.AutoObstacle_teleop_obstacle_bordertop{
  border-top:8px solid #e6883c
}
.dashboard-left-side-div-one-wrapper.state_Auto_system_fault_error{
  border: 5px solid rgb(228 51 41);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}
.robotRunningState_autoSystemFault{
  background-color: rgb(228 51 41);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid rgb(228 51 41);
}
.AutoSystem_fault_error_bordertop{
  border-top: 8px solid rgb(228 51 41);
}
.dashboard-left-side-div-one-wrapper.state_payload_state{
  border:5px solid #f0b156;
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}
.robotRunningState_payloadState{
  background-color: #f0b156;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid #f0b156;
}
.payloadState_bordertop{
border-top:8px solid #f0b156;
}
.dashboard-left-side-div-one-wrapper.state_Teleop_Autorun{
  border:5px solid rgb(113, 207, 98);
  padding: 10px 10px;
  border-radius: 7px;
  background-color: black;
}
.robotRunningState_teleop_Autorun,.robotRunningState_state_Teleop_Autorun{
  background-color: rgb(113, 207, 98);
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
  border: 2px solid rgb(113, 207, 98);
}
.Teleop_autoRun_borderTop{
border-top:8px solid rgb(113, 207, 98);
}
.robotRunningState_default{
  background-color: #282F42;
  border: 2px solid #a2a2a2;
  border-radius: 5px;
  padding: 8px 25px;
  font-size: 15px;
  color: white;
  font-weight: bold;
}
.dashboard-left-side-div-one-wrapper{
  border: 2px solid #a2a2a2;
}
.defaultBorderTop{
  border-top:8px solid #a2a2a2;
}
.RobotRunning_state_Button_wrapper {
  text-align: center;
  margin: 18px;
}
.tools {
  text-align: end;
  position: absolute;
  z-index: 999999;
  right: 20px;
}
.tools button {
  background:#3D4665 !important;
  font-weight: 600;
  margin: 5px;
  color: #BCC4DF;
  border: none;
  padding: 2px 12px;
  border-radius: 5px;
}
.Dashboard-content-div-one-left-side-h3 {
  color: white;
  text-align: left !important;
  font-weight: 700;
  font-size: 18px;
  padding: 15px 25px;
  margin-bottom: 0px;
}